import { FCM_MAINTENANCE_BANNER } from '@change/config/fcm/configs';

import { createFcmFunctions } from 'src/app/shared/hooks/fcm';

export const { useFcm, getFcm } = createFcmFunctions(
	{
		enabled: FCM_MAINTENANCE_BANNER,
	},
	{ hook: true, getter: true },
);
