import type { JSX } from 'react';

import { withPrefetchedData } from '@change/core/react/prefetch';

import { BannerSwitchContainer } from './BannerSwitch';
import { MaintenanceBannerContainer } from './MaintenanceBanner';

export const BannerContainer = withPrefetchedData(
	(): JSX.Element => {
		return (
			<>
				<MaintenanceBannerContainer />
				<BannerSwitchContainer />
			</>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: [MaintenanceBannerContainer, BannerSwitchContainer],
	},
);
