// This is a temporary solution to pass prefetched data from react-router to the PrefetchProvider

import type { PropsWithChildren } from 'react';

import flatten from 'lodash/flatten';
import type { RouteObject, ShouldRevalidateFunction } from 'react-router';
import { useLoaderData, useLocation, useParams } from 'react-router';
import { ScrollRestoration } from 'react-router-dom';

import { PrefetchProvider } from '@change/core/react/prefetch';
import { Redirect } from '@change/core/react/router';
import type { Session } from '@change/core/session';

import type { AppRedirect } from 'src/shared/routes';

import { ErrorBoundary, InvalidRoute } from 'src/app/app/error';
import { DefaultLayout } from 'src/app/app/layout';
import { CheckRestrictedAccess } from 'src/app/app/restrictedAccess';
import type { RouteLoaderData, RouteLoaderFunctionFactory } from 'src/app/app/router';
import type { LoadingContext } from 'src/app/app/topLoadingBar';
import { PageTracking } from 'src/app/app/tracking';
import { APP_REDIRECTS, APP_ROUTES, Preload } from 'src/app/routes';
import type { AppCache } from 'src/app/shared/hooks/cache';
import { FocusFallbackProvider } from 'src/app/shared/hooks/focus';
import { useQueryString } from 'src/app/shared/hooks/location';

import { App, InnerApp } from './App';
import type { AppOptions } from './types';

function PathBasedRedirect({ buildUrl, reason }: Omit<AppRedirect, 'path'>): JSX.Element {
	const { pathname: path, search: queryString } = useLocation();
	const params = useParams();
	const query = useQueryString();

	return <Redirect to={buildUrl({ path, query, queryString, params })} reason={reason} />;
}

// Ultimately, we should be able to use react-router's built-in prefetching capabilities
function PassPrefetchedData({ children }: PropsWithChildren<EmptyProps>): JSX.Element {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const prefetchData = useLoaderData() as RouteLoaderData;
	const location = useLocation();

	if (!prefetchData) return <>{children}</>;

	return (
		<PrefetchProvider
			/* We are using this technique to make sure that navigating within the same route will not result in partial UI updates (e.g. the sign count, then the hero image) */
			key={location.pathname}
			{...prefetchData}
		>
			{children}
		</PrefetchProvider>
	);
}

type Options = AppOptions & {
	context?: { cache?: AppCache; session?: Session };
	loadingContext?: LoadingContext;
	loaderFactory?: RouteLoaderFunctionFactory;
};

// we are using this to prevent a data load on a simple query params change
const shouldRevalidate: ShouldRevalidateFunction = ({ currentUrl, nextUrl }) => {
	return currentUrl.pathname !== nextUrl.pathname;
};

export function createRoutes({ context = {}, loadingContext, loaderFactory, ...options }: Options): RouteObject[] {
	return [
		{
			id: 'root',
			element: (
				<FocusFallbackProvider>
					{/* we don't want a query param change to result in a scroll to top */}
					<ScrollRestoration getKey={({ pathname }) => pathname} />
					<App {...options} />
				</FocusFallbackProvider>
			),
			children: [
				{
					id: 'inner-root',
					element: (
						<InnerApp
							history={options.history}
							utilities={options.utilities}
							loadingContext={loadingContext}
							context={context}
						/>
					),
					ErrorBoundary,
					children: flatten([
						...APP_ROUTES.map((route) => {
							const { id, path, feProxyPath, component, frame, preload } = route;
							// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/consistent-type-assertions
							const RouteComponent = component as any; // TODO find a way to properly type this
							const paths = [
								...(Array.isArray(path) ? path : [path]),
								// eslint-disable-next-line no-nested-ternary
								...(feProxyPath ? (Array.isArray(feProxyPath) ? feProxyPath : [feProxyPath]) : []),
							];
							const loader = loaderFactory?.(route);
							return paths.map(
								// eslint-disable-next-line @typescript-eslint/no-shadow
								(path, pathIdx): RouteObject => ({
									id: paths.length > 1 ? `${id}:${pathIdx}` : id,
									path,
									element: (
										<PassPrefetchedData>
											<CheckRestrictedAccess route={route}>
												<Preload preload={preload}>
													<PageTracking route={route} />
													<DefaultLayout
														hideFooter={!frame || (frame !== true && !frame.footer)}
														hideHeader={!frame || (frame !== true && !frame.header)}
													>
														<RouteComponent />
													</DefaultLayout>
												</Preload>
											</CheckRestrictedAccess>
										</PassPrefetchedData>
									),
									shouldRevalidate,
									ErrorBoundary,
									loader,
								}),
							);
						}),
						...APP_REDIRECTS.map(({ path, buildUrl, reason }, index) => ({
							id: `redirect-${index}`,
							path,
							element: <PathBasedRedirect buildUrl={buildUrl} reason={reason} />,
						})),
						{
							id: 'invalid-route',
							path: '/*',
							element: (
								<DefaultLayout>
									<InvalidRoute />
								</DefaultLayout>
							),
						},
					]),
				},
			],
		},
	];
}
