import { useMemo } from 'react';

// import { replace } from '@change/core/i18n';
// import { useI18n } from '@change/core/react/i18n';
import type { PrefetchUserContext } from '@change/core/react/prefetch';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { isLoaded } from 'src/app/shared/utils/async';

import type { BannerType, Copy, PrefetchedData, TosConfig } from './types';
import { getCopyFcm, useCopyFcm } from './useCopyFcm';

export function useCopy(
	bannerType: BannerType | undefined,
	config: TosConfig,
	prefetchedData?: Pick<PrefetchedData, 'tosBannerCopy' | 'tosBannerEffectiveDate'>,
): Copy {
	const fcmState = useCopyFcm();
	// TODO: re-add localize date logic once 'i18n' is available in PrefetchUserContext
	// currently not an issue because the copy FCM (tos_legal_notice_banner_copy) in production is not using those replacement values
	// FIXME: add tosBannerEffectiveDateLocalized to the prefetched data
	// const { localizeDate } = useI18n();
	const {
		errorReporter: { report },
	} = useUtilityContext();

	return useMemo(() => {
		if (prefetchedData) {
			if (!prefetchedData.tosBannerCopy) {
				return { body: '' };
			}
			// const date = new Date(prefetchedData.tosBannerEffectiveDate);
			return {
				body: prefetchedData.tosBannerCopy,
				// replace(prefetchedData.tosBannerCopy, {
				// tosEffectiveDateShort: localizeDate(date, 'date', 'short'),
				// tosEffectiveDateMedium: localizeDate(date, 'date', 'medium'),
				// tosEffectiveTime: localizeDate(date, 'time', 'short'),
				// }),
			};
		}
		if (!bannerType || !isLoaded(fcmState) || !fcmState.values.tosCopy || !config) {
			return { body: '' };
		}
		// const date = new Date(config.effectiveDate);
		try {
			return {
				body: fcmState.values.tosCopy[bannerType === 'post' ? 'postEffectiveText' : 'preEffectiveText'],
				// replace(fcmState.values.tosCopy[bannerType === 'post' ? 'postEffectiveText' : 'preEffectiveText'], {
				// tosEffectiveDateShort: localizeDate(date, 'date', 'short'),
				// tosEffectiveDateMedium: localizeDate(date, 'date', 'medium'),
				// tosEffectiveTime: localizeDate(date, 'time', 'short'),
				// }),
			};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			void report({
				// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
				error: e,
				params: { location: 'tos banner' },
			});
			return { body: '' };
		}
	}, [bannerType, fcmState, prefetchedData, config, report]);
}

export async function prefetchCopy(
	{ utilityContext }: PrefetchUserContext,
	bannerType: BannerType | undefined,
	config: TosConfig,
): Promise<string> {
	const fcm = await getCopyFcm(utilityContext);

	if (!bannerType || !fcm.tosCopy || !config) {
		return '';
	}

	return fcm.tosCopy[bannerType === 'post' ? 'postEffectiveText' : 'preEffectiveText'];
}
