import type { JSX } from 'react';

import { parseHtml } from '@change/core/react/html';
import { Text } from '@change/design-system/typography';

import { BannerWrapper } from 'src/app/shared/components/banners/layout';

type Props = {
	message: string;
};

export function MaintenanceBanner({ message }: Props): JSX.Element | null {
	return (
		<BannerWrapper py={4}>
			<Text as="div" size="small">
				{parseHtml(message)}
			</Text>
		</BannerWrapper>
	);
}
