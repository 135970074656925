import type { JSX } from 'react';

import type { PrefetchedUserDataProps } from '@change/core/react/prefetch';
import { withPrefetchedData } from '@change/core/react/prefetch';

import { MaintenanceBanner } from '../components/MaintenanceBanner';
import { prefetchMaintenanceBanner, useMaintenanceBanner } from '../hooks/maintenanceBanner';

export const MaintenanceBannerContainer = withPrefetchedData(
	({ prefetchedUserData }: PrefetchedUserDataProps<{ maintenanceMessage?: string }>): JSX.Element | null => {
		const maintenanceBannerMessage = useMaintenanceBanner(prefetchedUserData?.maintenanceMessage);

		if (!maintenanceBannerMessage) return null;

		return <MaintenanceBanner message={maintenanceBannerMessage} />;
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchUserData: async (context) => {
			return { maintenanceMessage: await prefetchMaintenanceBanner(context) };
		},
	},
);
