import type { UtilityContext } from '@change/core/react/utilityContext';

import type { RestrictedAccessCheckSessionInfoQuery } from './api.graphql';
import { RestrictedAccessCheckSessionInfo } from './api.graphql';

type Result = RestrictedAccessCheckSessionInfoQuery['viewer'];

export async function getRestrictedAccessCheckSessionInfo({ gql: { fetch } }: UtilityContext): Promise<Result> {
	const { data } = await fetch<RestrictedAccessCheckSessionInfoQuery>({
		query: RestrictedAccessCheckSessionInfo,
		important: true,
		rejectOnError: true,
	});
	if (!data?.viewer) {
		throw new Error('unexpected empty data');
	}
	return data.viewer;
}
