import type {
	PrefetchContext,
	PrefetchContextData,
	PrefetchDependenciesContext,
	PrefetchDependency,
	PrefetchUserContextData,
} from '@change/core/react/prefetch';

import { resolvePrefetchableComponent } from './resolveComponent';

export async function prefetchComponentData(
	comp: PrefetchDependency,
	options: Omit<PrefetchContext, 'getUserData'>,
	prefetchedUserDataPromise: Promise<PrefetchUserContextData>,
): Promise<PrefetchContextData> {
	const component = await resolvePrefetchableComponent(comp);

	if (!component.prefetchName) {
		return undefined;
	}

	const getUserData = async () => (await prefetchedUserDataPromise)?.[component.prefetchName];

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const data = component.prefetchData ? await component.prefetchData({ ...options, getUserData }) : undefined;

	const prefetchDependenciesOptions: PrefetchDependenciesContext = {
		l10n: options.l10n,
		path: options.path,
		params: options.params,
		query: options.query,
		session: options.session,
		getUserData,
	};
	const dependencies = await Promise.resolve(component.prefetchDependencies?.(prefetchDependenciesOptions) || []);
	const depData = await Promise.all([
		...dependencies.map(async (dep) => prefetchComponentData(dep, options, prefetchedUserDataPromise)),
	]);

	const combinedData = depData.reduce<PrefetchContextData>(
		(acc, dep) => {
			const combined = {
				...acc,
				...dep,
			};
			// if the sum of keys is not the same as the combined object's # of keys,
			// it means that dep has keys that were already in acc, which is not supposed to happen
			// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument, @typescript-eslint/consistent-type-assertions
			if (Object.keys(acc as any).length + Object.keys(dep || {}).length !== Object.keys(combined).length) {
				throw new Error('Prefetched data overrides other prefetched data');
			}
			return combined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		data ? { [component.prefetchName]: data } : {},
	);

	return combinedData;
}
