import { useCallback } from 'react';

import { useTracking as useTrackingBase } from '@change/core/react/tracking';

import type { BannerType } from './types';

type Result = {
	trackBannerView: () => void;
	trackLinkClick: () => void;
	trackDismiss: () => void;
};

export function useTracking(bannerType: BannerType | undefined): Result {
	const track = useTrackingBase();

	return {
		trackBannerView: useCallback(() => {
			void track('tos_banner_view', { before_effective_date: bannerType === 'pre' });
		}, [track, bannerType]),
		trackLinkClick: useCallback(() => {
			void track('tos_banner_click_to_tos', { before_effective_date: bannerType === 'pre' });
		}, [track, bannerType]),
		trackDismiss: useCallback(() => {
			void track('tos_banner_click_to_close', { before_effective_date: bannerType === 'pre' });
		}, [track, bannerType]),
	};
}
