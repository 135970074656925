import { useCallback } from 'react';

import type { PrefetchUserContext } from '@change/core/react/prefetch';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { useCookiePrefAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { getCookieName } from './shared/getCookieName';
import type { BannerType, PrefetchedData, TosConfig } from './types';

type Result = {
	getCount: () => number;
	decreaseCount: (count?: number) => void;
};

function getMaxDisplayCount(config: TosConfig, prefetchedData?: Pick<PrefetchedData, 'tosBannerMaxDisplayCount'>) {
	return prefetchedData ? prefetchedData.tosBannerMaxDisplayCount : config?.maxDisplayCount || 0;
}
function getCookieExpiration(
	bannerType: BannerType | undefined,
	config: TosConfig,
	prefetchedData?: Pick<PrefetchedData, 'tosBannerRange'>,
) {
	return prefetchedData
		? prefetchedData.tosBannerRange
		: config?.[bannerType === 'post' ? 'postEffectiveRange' : 'preEffectiveRange'];
}

export function useCookie(
	bannerType: BannerType | undefined,
	config: TosConfig,
	prefetchedData?: Pick<
		PrefetchedData,
		'tosBannerType' | 'tosBannerRange' | 'tosBannerMaxDisplayCount' | 'tosBannerEffectiveDate'
	>,
): Result {
	const cookieAllowedState = useCookiePrefAsync('preferences');
	const cookieAllowed = isLoaded(cookieAllowedState) ? cookieAllowedState.value : false;

	const cookieName = getCookieName(bannerType, config?.effectiveDate, prefetchedData);
	const { cookies } = useUtilityContext();

	const getDisplayCount = useCallback(() => {
		const maxDisplayCount = getMaxDisplayCount(config, prefetchedData);
		if (!maxDisplayCount || !cookieName) return 0;
		const displayCountStr = cookies.get(cookieName);
		if (!displayCountStr || !/^\d+$/.test(displayCountStr)) return maxDisplayCount;
		try {
			return parseInt(displayCountStr, 10);
		} catch (e) {
			return 0;
		}
	}, [cookies, cookieName, prefetchedData, config]);

	return {
		getCount: getDisplayCount,
		decreaseCount: useCallback(
			(count?: number) => {
				const expires = getCookieExpiration(bannerType, config, prefetchedData);
				if (!cookieName || !cookieAllowed || !expires) return;
				cookies.set(cookieName, `${Math.max(0, count === undefined ? getDisplayCount() - 1 : count)}`, {
					path: '/',
					expires,
				});
			},
			[cookies, prefetchedData, config, cookieName, cookieAllowed, bannerType, getDisplayCount],
		),
	};
}

export function prefetchCookie(
	{ utilityContext }: PrefetchUserContext,
	bannerType: BannerType | undefined,
	config: TosConfig,
): number {
	const cookieName = getCookieName(bannerType, config?.effectiveDate);

	if (!config || !cookieName) return 0;

	const displayCountStr = utilityContext.cookies.get(cookieName);
	if (!displayCountStr || !/^\d+$/.test(displayCountStr)) return config.maxDisplayCount;
	try {
		return parseInt(displayCountStr, 10);
	} catch (e) {
		return 0;
	}
}
