import type { Params } from '@remix-run/router';

import type { UtilityContext } from '@change/core/react/utilityContext';
import type { Session } from '@change/core/session';
import { getLocation } from '@change/core/window';

import type { AppRoute } from 'src/shared/routes';

import { getLoginRedirectUrl } from 'src/app/shared/routes';

import { getExpectedBehavior } from './getExpectedBehavior';

export async function checkRestrictedAccess(context: {
	route: AppRoute;
	url: URL;
	params: Params;
	session: Session;
	utilityContext: UtilityContext;
}): Promise<boolean> {
	const expectedBehavior = await getExpectedBehavior(context);
	const { url } = context;

	switch (expectedBehavior) {
		case 'external':
			getLocation().assign(url);
			return false;
		case 'login_page':
			getLocation().assign(getLoginRedirectUrl(url.pathname + url.search));
			return false;
		case 'forbidden':
			getLocation().assign(url);
			return false;
		default:
			return true;
	}
}
