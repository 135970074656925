import { type JSX, useEffect, useMemo } from 'react';

import { useLocation } from 'react-router';

import { SetPageStateValue } from '@change/core/react/pageState';
import { useTracking } from '@change/core/react/tracking';

import type { AppRoute } from 'src/shared/routes';

import { getRouteConfigFromPath } from 'src/app/routes';

export function PageTracking({ route }: { route: AppRoute }): JSX.Element | null {
	const track = useTracking();
	const { pathname } = useLocation();

	useEffect(() => {
		void track('page_view', {
			route_id: route.id,
			pathname,
		});
		// we want to make sure to only trigger this on path change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname, route]);
	const pageStateRoute = useMemo(() => {
		const routeConfig = getRouteConfigFromPath(pathname);
		if (!routeConfig) return undefined;
		return {
			id: route.id,
			pathname,
			path: routeConfig.path,
			params: routeConfig.params,
		};
	}, [pathname, route]);

	return <SetPageStateValue name="route" value={pageStateRoute}></SetPageStateValue>;
}
