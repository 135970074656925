import { useEffect, useState } from 'react';
import type { JSX } from 'react';

import { translationKey } from '@change/core/i18n';
import { Translate } from '@change/core/react/i18n';
import { VisuallyHidden } from '@change/design-system/a11y';

import { useCookieWallDisplayed } from '../hooks/useCookieWallDisplayed';

import { CookieWallContainer } from './CookieWall';

export function CookieWallWrapperContainer(): JSX.Element | null {
	const [alert, setAlert] = useState<'success' | 'error' | undefined>();
	const showCookieWall = useCookieWallDisplayed();

	useEffect(() => {
		if (!alert) return undefined;

		const timeout = window.setTimeout(() => setAlert(undefined), 10000);

		return () => {
			window.clearTimeout(timeout);
		};
	}, [alert]);

	return (
		<>
			{showCookieWall && (
				<CookieWallContainer
					onAccept={() => setAlert('success')}
					onReject={() => setAlert('success')}
					onError={() => setAlert('error')}
				/>
			)}
			{alert && (
				<VisuallyHidden role="alert">
					<Translate value={translationKey(`corgi.cookie_wall.${alert}`)} />
				</VisuallyHidden>
			)}
		</>
	);
}
